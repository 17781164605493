import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../components/MarkdownWrapper';
import Layout from '../../components/Layout';
export const _frontmatter = {
  "title": "TypeScript",
  "path": "/knowledge/hello-world",
  "date": "2022-08-01T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "guides",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#guides",
            "aria-label": "guides permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Guides`}</h1>
        <h2 {...{
          "id": "typescript",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#typescript",
            "aria-label": "typescript permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`TypeScript`}</h2>
        <h3 {...{
          "id": "generics",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#generics",
            "aria-label": "generics permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Generics`}</h3>
        <h4 {...{
          "id": "problem",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#problem",
            "aria-label": "problem permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Problem`}</h4>
        <p>{`Let's say we have a function that takes a callback function and call it.`}</p>
        <p>{`Here's our function:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`const mainFn = (cb) => cb()
`}</code></pre>
        <p>{`And the functions that get passed to it are:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`// One that returns a number
mainFn(
  () => 5
)

// One that returns a string
mainFn(
  () => 'string'
)

// Another than returns an array
mainFn(
  () => [...] // Array that contain numbers
)
`}</code></pre>
        <p>{`Our function `}<inlineCode parentName="p">{`mainFn`}</inlineCode>{` can handle these easily, but an issue arose. We're using TypeScript, so we have to define the type of `}<inlineCode parentName="p">{`cb`}</inlineCode>{` and `}<inlineCode parentName="p">{`mainFn`}</inlineCode>{` return type.`}</p>
        <p>{`Easiest solution is to hardcode the types:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`const mainFn = (
  cb: (() => number) | (() => string) | (() => number[])
): number | string | number[] => cb()
`}</code></pre>
        <p>{`Now let's say `}<inlineCode parentName="p">{`mainFn`}</inlineCode>{` will be called 100 times, with 100 different function functions, and each function has a different return type. Hardcoding a 100 type is overkill.`}</p>
        <p>{`Wouldn't it be amazing to have a feature that makes it possible to pass the return type the moment we call the function? Here comes the `}<inlineCode parentName="p">{`Generics`}</inlineCode>{` to help!`}</p>
        <h4 {...{
          "id": "solution",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#solution",
            "aria-label": "solution permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Solution`}</h4>
        <p>{`To fix that issue, we can define `}<inlineCode parentName="p">{`mainFn`}</inlineCode>{` like this:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`const mainFn = <T>(cb: () => T): T => cb()
`}</code></pre>
        <p>{`The syntax of Generics is `}<inlineCode parentName="p">{`<>`}</inlineCode>{`. Which works similar to parameters, but instead is a placeholder for types. `}<inlineCode parentName="p">{`T`}</inlineCode>{` is the name we have given to the first placeholder slot. It could be `}<inlineCode parentName="p">{`<ReturnType>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<PotatoWalkingDownTheRoad`}</inlineCode>{`.`}</p>
        <p>{`Compared with parameters:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`// Parameter syntax
// Wraps its placeholders with parenthesis
(num1, num2) => num1 + num2

// Generics syntax
// Wraps its placeholders with angle brackets
// and comes before the parameters
<T1, T2>(num1: T1, num2: T2) => num1 + num2
`}</code></pre>
        <p>{`Back to our `}<em parentName="p">{`main`}</em>{` issue, we can solve it by passing the type everytime we call `}<inlineCode parentName="p">{`mainFn`}</inlineCode>{`:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`// One that returns a number
// We define the return type as number through generics
mainFn<number>(
  () => 5
)

// One that returns a string
// We define the return type as string through generics
mainFn<string>(
  () => 'string'
)

// Another than returns an array
// We define the return type as number[] through generics
mainFn<number[]>(
  () => [...] // Array that contain numbers
)
`}</code></pre>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      